import React, { Component } from 'react'

import './Map.scss'

class Map extends Component {
    render() {
        return (
            <div className='map wow animate__animated animate__fadeIn'>
                <iframe
                    src={this.props.src}
                    width={this.props.width}
                    height={this.props.height}
                    frameBorder={this.props.frameBorder}
                />
            </div>
        )
    }
}

export default Map
