import React, { Component } from 'react'
import { connect } from 'react-redux'

import './Footer.scss'

class Footer extends Component {
    render() {
        return (
            <footer>
                <p className='sava'>
                    <span>{this.props.developedBy}</span>
                    <a href={this.props.savaHref}>{this.props.sava}</a>
                </p>
                <p className='footer-date'>{this.props.date}</p>
            </footer>
        )
    }
}

function mapStateToProps(state) {
    return {
        savaHref: state.footerReducer.savaHref,
        developedBy: state.footerReducer.developedBy,
        sava: state.footerReducer.sava,
        date: state.footerReducer.date,
    }
}

export default connect(mapStateToProps)(Footer)
