import React, { Component } from 'react'
import { Link, animateScroll as scroll } from 'react-scroll'

import Icons from '../Icons/Icons'

import './Navigation.scss'

class Navigation extends Component {
    state = {
        isOpen: false,
        count: 0,
        burgerClasses: 'burger',
        navClasses: 'inactive-nav',
    }
    closeMenu = () => {}
    toggleMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen,
            burgerClasses: !this.state.isOpen ? 'active-burger' : 'burger',
            navClasses: !this.state.isOpen
                ? 'active-nav animate__animated animate__slideInLeft'
                : 'inactive-nav',
        })
    }

    openMenu = (e) => {
        this.setState({
            isOpen: true,
            burgerClasses: 'active-burger',
            navClasses: 'active-nav animate__animated animate__slideInLeft',
        })
    }

    closeMenu = (e) => {
        this.setState({
            isOpen: false,
            burgerClasses: 'burger',
            navClasses: 'active-nav animate__animated animate__slideOutLeft',
        })
    }

    render() {
        return (
            <div className='menu'>
                <nav className={this.state.navClasses}>
                    {this.props.links.map((link, index) => {
                        return (
                            <Link
                                activeClass='active-link'
                                to={link.href}
                                spy={true}
                                smooth={true}
                                offset={0}
                                duration={300}
                                key={index}>
                                {link.anchor}
                            </Link>
                        )
                    })}
                    <Icons visibleOn='mobile' icons={this.props.icons} />
                </nav>
                <div className={this.state.burgerClasses}>
                    <span
                        onClick={
                            this.state.isOpen ? this.closeMenu : this.openMenu
                        }></span>
                </div>
            </div>
        )
    }
}

export default Navigation
