import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import {createStore} from 'redux'
import rootReducer from './redux/reducers/rootReducer'
import {Provider} from 'react-redux'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css'
import './index.scss'

const store = createStore(rootReducer)
const root = ReactDOM.createRoot(document.getElementById('root'));
console.log(store.getState())
const app = (
        <Provider store={store}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Provider>
)
root.render(app)
