import React, { Component } from 'react'
import { connect } from 'react-redux'

import './PopAskButton.scss'
import '../../App.scss'

class PopAskButton extends Component {
    render() {
        return (
            <div
                className={
                    this.props.isVisiblePopAsk
                        ? 'invisible'
                        : 'pop-ask-button animate__animated animate__shakeY animate__infinite animate__slower'
                }
                onClick={this.props.togglePopAsk}>
                {this.props.popAsk.buttonText}
            </div>
        )
    }
}

export default PopAskButton
