import React, { Component } from 'react'

import TabsContent from '../TextImageSection/TabsContent/TabsContent'
import SimpleContent from '../../Components/SimpleContent/SimpleContent'

import './TextSection.scss'

class TextSection extends Component {
    render() {
        let sectionTitle, sectionContent
        let sectionClasses = 'text-image-section'

        switch (this.props.section.typeTitle) {
            case 'h1':
                sectionTitle = (
                    <h1 className='section-title'>
                        {this.props.section.sectionTitle}
                    </h1>
                )
                break
            default:
                sectionTitle = (
                    <h2 className='section-title'>
                        {this.props.section.sectionTitle}
                    </h2>
                )
                break
        }

        switch (this.props.section.typeContent) {
            case 'tabs':
                sectionContent = (
                    <TabsContent
                        innerImg={'../../assets/img/football.jpg'}
                        content={this.props.section.content}
                    />
                )
                break
            default:
                sectionContent = (
                    <SimpleContent
                        innerImg={'../../assets/img/football.jpg'}
                        content={this.props.section.content}
                    />
                )
                break
        }

        return (
            <section
                className={
                    'text-section ' +
                    ' wow animate__animated animate__fadeIn ' +
                    this.props.section.name +
                    '-section'
                }
                id={this.props.section.href}>
                {sectionTitle}
                {sectionContent}
            </section>
        )
    }
}

export default TextSection
