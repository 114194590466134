import i1 from '../../assets/img/futsal.jpg'
import i2 from '../../assets/img/poigratVminifutbolIfutbol.jpeg'
import i3 from '../../assets/img/football.jpg'

const initialState = {
    darkText: 'Приходи играть с нами!',
    firstImage: i1,
    altFirstImage: 'Поиграть в мини футбол в зале',
    secondImage: i2,
    altSecondImage: 'Поиграть в мини футбол и в футбол',
    thirdImage: i3,
    altThirdImage: 'Поиграть в футбол на траве',
    buttonText: 'Играть с нами',
    buttoHref: '#',
}

export default function headerReducer(state = initialState, action) {
    return state
}
