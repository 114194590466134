import { combineReducers } from 'redux'

import appReducer from './appReducer'
import headerReducer from './headerReducer'
import invitationReducer from './invitationReducer'
import popAskReducer from './popAskReducer'
import footerReducer from './footerReducer'

export default combineReducers({
    appReducer,
    headerReducer,
    invitationReducer,
    popAskReducer,
    footerReducer,
})
