import React, { Component } from 'react'

import './SimpleContent.scss'

class SimpleContent extends Component {
    render() {
        let img = null,
            imgClass = 'withoutImg'
        if (this.props.innerImg) {
            img = <div className='section-inner-img'></div>
            imgClass = 'withImg'
        }
        return (
            <div className={'section-simple-content ' + imgClass}>
                <div className='section-inner-content'>
                    {this.props.content}
                </div>
                {img}
            </div>
        )
    }
}

export default SimpleContent
