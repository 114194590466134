import React from 'react'
const initialState = {
    buttonText: 'Задать вопрос',
    cardMainText: 'У вас остались вопросы?',
    cardBottomText: 'Вы можете задать нам вопрос в соцесетях:',
    cardCenterText: 'ИЛИ',
    cardTopText: 'Вы можете задать свой вопрос ниже:',
}

export default function popAskReducer(state = initialState, action) {
    return state
}
