import React, { Component } from 'react'

import './PopAskCard.scss'

class PopAskCard extends Component {
    state = {
        name: this.props.popAsk.nameValue,
        phone: this.props.popAsk.phoneValue,
        question: this.props.popAsk.questionValue,
        popAskCardClass: 'pop-ask-card animate__animated animate__slideInDown',
        cardTopClass: 'pop-ask-card-top',
        cardSuccessClass: 'success invisible',
        isSend: this.props.isSend,
    }

    handleFocus = (e) => {
        switch (e.target.name) {
            case 'name':
                if (e.target.value === 'Ваше имя') e.target.value = ''
                break
            case 'phone':
                if (e.target.value === 'Ваш номер телефона (обязательно!)')
                    e.target.value = ''
                break
            case 'question':
                if (e.target.value === 'Ваш вопрос') e.target.value = ''
                break
        }
    }

    handleChange = (e) => {
        switch (e.target.name) {
            case 'name':
                this.setState({ name: e.target.value })
                break
            case 'phone':
                this.setState({ phone: e.target.value })
                break
            case 'question':
                this.setState({ question: e.target.value })
                break
        }
    }

    handleSubmit = (e) => {
        let token = '5640311848:AAF99zH89qh5lZ0H9SDJ1d1OM8pGfeGlvcU'
        let chat_id = -1001178428112
        let result = `<b>Имя:</b> ${this.state.name}%0A<b>Телефон:</b>${this.state.phone}%0A<b>Вопрос:</b>${this.state.question}`
        let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${result}&parse_mode=html`
        let api = new XMLHttpRequest()
        api.open('GET', url, true)
        api.send()
        this.setState({
            isSend: true,
            cardRTopClass:
                'pop-ask-card-top animate__animated animate__flipOutY',
            cardSuccessClass: 'success animate__animated animate__flipInY',
        })
        setTimeout(() => {
            this.setState({
                cardTopClass: 'pop-ask-card-top op0',
            })
        }, 100)
        e.preventDefault()
    }

    closePopAsk = (e) => {
        this.setState({
            popAskCardClass:
                'pop-ask-card animate__animated animate__slideOutUp',
        })
        setTimeout(() => {
            this.props.togglePopAsk()
        }, 600)
        setTimeout(() => {
            this.setState({
                popAskCardClass:
                    'pop-ask-card animate__animated animate__slideInDown',
            })
        }, 1000)
    }

    render() {
        return (
            <div
                className={
                    this.props.isVisiblePopAsk
                        ? 'pop-ask-card-darker'
                        : 'invisible'
                }>
                <div className={this.state.popAskCardClass}>
                    <div className='times' onClick={this.closePopAsk}></div>
                    <div className={this.state.cardSuccessClass}>
                        <p>Ваш вопрос успешно отправлен!</p>
                        <span>Мы свяжемся с Вами в ближайшее время.</span>
                        <i className='fa fa-thumbs-o-up fa-3x'></i>
                    </div>
                    <p className='pop-ask-card-main-text'>
                        {this.props.popAsk.cardMainText}
                    </p>
                    <div className={this.state.cardTopClass}>
                        <p className='pop-ask-card-top-text'>
                            {this.props.popAsk.cardRightText}
                        </p>
                        <form
                            className='pop-ask-card-form'
                            onSubmit={this.handleSubmit}>
                            <div className='pop-input'>
                                <i className='fa fa-user fa-2x' />
                                <input
                                    type='text'
                                    name='name'
                                    defaultValue='Ваше имя'
                                    onFocus={this.handleFocus}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className='pop-input'>
                                <i className='fa fa-phone fa-2x ' />
                                <input
                                    type='text'
                                    name='phone'
                                    required
                                    defaultValue='Ваш номер телефона (обязательно!)'
                                    onFocus={this.handleFocus}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className='pop-text-area'>
                                <i className='fa fa-question fa-2x' />
                                <textarea
                                    name='question'
                                    defaultValue='Ваш вопрос'
                                    onFocus={this.handleFocus}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <button type='submit'>
                                {this.props.popAsk.sendButtonText}
                            </button>
                        </form>
                    </div>
                    <div className='pop-ask-card-bottom'>
                        <p className='pop-ask-card-bottom-text'>
                            {this.props.popAsk.cardLeftText}
                        </p>
                        <div className='pop-ask-card-social'>
                            <a href={this.props.popAsk.telegramHref}>
                                <i className='fa fa-telegram'></i>
                            </a>
                            <a href={this.props.popAsk.vkHref}>
                                <i className='fa fa-vk'></i>
                            </a>
                            <a href={this.props.popAsk.whatsappHref}>
                                <i className='fa fa-whatsapp'></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopAskCard
