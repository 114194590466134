import React, { Component } from 'react'

import './Tabs.scss'

class Tabs extends Component {
    render() {
        return (
            <div className='tabs'>
                <ul>
                    {this.props.tabs.map((tab, index) => {
                        if (index === this.props.currentTab)
                            return (
                                <li className='currentTab' key={index}>
                                    {tab.tabName}
                                </li>
                            )
                        else
                            return (
                                <li
                                    key={index}
                                    onClick={() => {
                                        this.props.handleToggle(index)
                                    }}>
                                    {tab.tabName}
                                </li>
                            )
                    })}
                </ul>
            </div>
        )
    }
}

export default Tabs
