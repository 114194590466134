import React, { Component } from 'react'

import './Principles.scss'

class Principles extends Component {
    render() {
        return (
            <ul className='principles'>
                <li className='wow animate__animated animate__slideInLeft'>
                    <span className='principles-digit'>1</span>
                    <span className='principles-title'>Дружный спорт</span>
                    <span className='principles-description'>
                        Уважаем партнёров и соперников
                    </span>
                </li>
                <li className='wow animate__animated animate__slideInRight'>
                    <span className='principles-digit'>2</span>
                    <span className='principles-title'>Позитивный взгляд</span>
                    <span className='principles-description'>
                        С нами поддержка и улыбка
                    </span>
                </li>
                <li className='wow animate__animated animate__slideInLeft'>
                    <span className='principles-digit'>3</span>
                    <span className='principles-title'>Честность в борьбе</span>
                    <span className='principles-description'>
                        Играем корректно и фаирплай
                    </span>
                </li>
                <li className='wow animate__animated animate__slideInRight'>
                    <span className='principles-digit'>4</span>
                    <span className='principles-title'>Командный подход</span>
                    <span className='principles-description'>
                        Один за всех и все за одного
                    </span>
                </li>
            </ul>
        )
    }
}

export default Principles
