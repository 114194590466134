import React, { Component } from 'react'

import './PopRegistrationCard.scss'

class PopRegistrationCard extends Component {
    state = {
        name: '',
        phone: '',
    }
    handleFocus = (e) => {
        if (e.target.name === 'name') {
            if (e.target.value === 'Имя') e.target.value = ''
        } else {
            if (e.target.value === 'Номер телефона') e.target.value = ''
        }
    }

    handleFocus = (e) => {
        switch (e.target.name) {
            case 'name':
                if (e.target.value === 'Имя') e.target.value = ''
                break
            case 'phone':
                if (e.target.value === 'Номер телефона') e.target.value = ''
                break
        }
    }

    handleChange = (e) => {
        switch (e.target.name) {
            case 'name':
                this.setState({ name: e.target.value })
                break
            case 'phone':
                this.setState({ phone: e.target.value })
                break
        }
    }

    handleSubmit = (e) => {
        //https://api.telegram.org/bot5640311848:AAF99zH89qh5lZ0H9SDJ1d1OM8pGfeGlvcU/getUpdates
        let token = '5640311848:AAF99zH89qh5lZ0H9SDJ1d1OM8pGfeGlvcU'
        let chat_id = -1001178428112
        let result = `<b>Имя:</b> ${this.state.name}%0A<b>Телефон:</b>${this.state.phone}`
        let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${result}&parse_mode=html`
        let api = new XMLHttpRequest()
        api.open('GET', url, true)
        api.send()
        console.log('Сообщение успешно отправлено')
        e.preventDefault()
    }
    render() {
        return (
            <div
                className={
                    this.props.isVisiblePopRegistration
                        ? 'pop-registration-darker'
                        : 'invisible'
                }>
                <div className='pop-registration'>
                    <div className='cross'>
                        <i
                            className='fa fa-times'
                            onClick={this.props.togglePopRegistration}></i>
                    </div>
                    <p className='main-text-registration'>
                        Хочешь играть с нами?
                    </p>
                    <p className='sub-text-registration'>
                        Тогда регистрируйся ниже!
                    </p>
                    <form onSubmit={this.handleSubmit}>
                        <div className='name-field'>
                            <div className='icon-field'>
                                <i className='fa fa-user fa-2x'></i>
                            </div>
                            <input
                                type='text'
                                name='name'
                                value={this.state.name}
                                onFocus={this.handleFocus}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className='phone-field'>
                            <div className='icon-field'>
                                <i className='fa fa-phone'></i>
                            </div>
                            <input
                                type='text'
                                name='phone'
                                value={this.state.phone}
                                onFocus={this.handleFocus}
                                onChange={this.handleChange}
                            />
                        </div>
                        <button type='submit'>Отправить</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default PopRegistrationCard
