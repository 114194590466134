import React, {Component} from 'react'


import './Icons.scss'

class Icons extends Component {
    render() {
        return (
            <ul className={(this.props.visibleOn === 'desktop')?'desktop-icons':'mobile-icons'}>
                {this.props.icons.map((icon, index) => {
                    return (
                        <li key={index}>
                            <a className="icon" href={icon.href}><i className={`fa fa-3x ${icon.class}`}/></a>
                        </li>
                    )
                })}
            </ul>
        )
    }
}

export default Icons