import React, { Component } from 'react'
import Tabs from './Tabs/Tabs'

import './TabsContent.scss'

class TabsContent extends Component {
    state = {
        currentTab: 0,
    }

    handleToggle = (index) => {
        this.setState({
            currentTab: index,
        })
    }

    render() {
        return (
            <section className='tabs-content'>
                <Tabs
                    tabs={this.props.content}
                    currentTab={this.state.currentTab}
                    handleToggle={this.handleToggle}
                />
                <div className='tabsContent'>
                    {this.props.content[this.state.currentTab].innerContent}
                </div>
            </section>
        )
    }
}

export default TabsContent
