const initialState = {
    savaHref: 'http://the-sava.ru',
    developedBy: 'Developed BY ',
    sava: 'Sava',
    date: `2017 - ${new Date().getFullYear()}`,
}

export default function footerReducer(state = initialState, action) {
    return state
}
