import React from 'react'

import futbol from '../../assets/img/futbol.jpg'
import futsal from '../../assets/img/futsal.jpg'

import Schedule from '../../Components/Schedule/Schedule'
import Map from '../../Components/Map/Map'
import Principles from '../../Components/Principles/Principles'

const initialState = {
    textSections: [
        {
            typeTitle: 'h1',
            sectionTitle: 'Поиграть в футбол в Москве',
            typeContent: 'center',
            href: '#info',
            isCentered: false,
            content: (
                <React.Fragment>
                    <p>
                        На этом сайте вы найдете ответ на многие вопросы,
                        которые так актуальны для вас:
                    </p>
                    <ul>
                        <li>где поиграть в футбол;</li>
                        <li>с кем поиграть в футбол;</li>
                        <li>как поиграть в футбол в зале.</li>
                    </ul>
                    <p>Но обо всем по порядку.</p>
                    <p>
                        Итак, Вы хотите найти, с кем поиграть в футбол или
                        мини-футбол в Москве? Мы предлагаем вам присоединится к
                        нашей дружной компании и прекрасно провести время в мире
                        голов, острых моментов и побед! Пройдите по
                        кнопке-ссылке и вопросы: где, когда и с кем поиграть в
                        футбол в Москве - для вас перестанут существовать.
                        Почему? Да потому, что мы предлагаем вам:
                        <ol>
                            <li>прекрасный коллектив;</li>
                            <li>отличный зал;</li>
                            <li>великолепное поле;</li>
                            <li>игры круглый год и в любую погоду;</li>
                            <li>в любой день недели;</li>
                            <li>видео и фото с матчей;</li>
                            <li>интересные турниры;</li>
                            <li>всегда актуально — азарт;</li>
                            <li>превосходную атмосферу файр-плея;</li>
                            <li>удобный и прозрачный режим записи на игры.</li>
                        </ol>
                    </p>
                    <p>
                        У нас приветствуется любой уровень игры в мини-футбол
                        или футзал. Если вы живёте в Москве в ЮЗАО или ЗАО, или
                        даже в любом районе или приехали в Москву, то вам точно
                        к нам ) Если ищете с друзьями, где и с кем поиграть в
                        любительский футбол, но сами без команды, если после
                        работы хочется поиграть в футбол в зале или на траве, то
                        тоже к нам. Мы ждём вас на наших играх мини-футбол,
                        футзал и футбол 5 на 5 !
                    </p>
                </React.Fragment>
            ),
        },
        {
            typeTitle: 'h2',
            name: 'important',
            sectionTitle: 'Важное',
            typeContent: 'tabs',
            href: 'important',
            isCentered: false,
            content: [
                {
                    tabName: 'Принципы',
                    innerContent: <Principles />,
                },
                {
                    tabName: 'Правила',
                    innerContent: (
                        <React.Fragment>
                            <p>
                                Добровольная организация любителей спорта
                                (футбол, волейбол, теннис). Организация создана
                                на базах группы соцсети ВКонтакте
                                https://vk.com/minifootballuzao и
                                Телеграм-канала
                                https://t.me/mini_football_moscow
                            </p>
                            <p>
                                Целью организации является создание условий для
                                проведения спортивного досуга ее участниками .
                            </p>
                            <h3>Общие правила для сообщества</h3>
                            <p>
                                Сообщество представляет собой группу участников,
                                принимающих и выполняющих правила организации.
                            </p>
                            <p>
                                В сообществе существуют:
                                <ul>
                                    <li>
                                        Администрация — ответственные члены
                                        сообщества, участвующие в создании
                                        организации и ее движении вперед;
                                    </li>
                                    <li>
                                        Актив — активные члены сообщества,
                                        управляющие и контролирующие проведение
                                        мероприятий;
                                    </li>
                                    <li>Участники — ВСЕ МЫ.</li>
                                </ul>
                            </p>
                            <ol className='hlist'>
                                <li>
                                    <h4>
                                        Приветствуется в действиях участников:
                                    </h4>
                                    <ol>
                                        <li>
                                            Активное участие в спортивных и иных
                                            мероприятиях, проводимых
                                            сообществом;
                                        </li>
                                        <li>
                                            Посильная помощь и содействие в
                                            организации мероприятий, как
                                            администрации, так и товарищам по
                                            организации;
                                        </li>
                                        <li>
                                            Предложения по улучшению
                                            организации, адресованные
                                            администрации группы;
                                        </li>
                                        <li>Фэйр-плэй в игровых моментах;</li>
                                        <li>
                                            Поддержание принципа равенства при
                                            распределении ресурсов между
                                            игроками и командами;
                                        </li>
                                        <li>
                                            Внимательное и бережное отношение к
                                            имуществу сообщества и его
                                            участников;
                                        </li>
                                        <li>
                                            Пропаганда здорового образа жизни
                                            (далее — ЗОЖ);
                                        </li>
                                        <li>
                                            Действия по принципам коллективизма
                                            и взаимовыручки;
                                        </li>
                                        <li>
                                            Корректная, красивая и
                                            результативная игра на мероприятиях
                                            группы;
                                        </li>
                                        <li>
                                            Отзывчивость в коммуникации внутри
                                            сообщества как в личке, так и в
                                            группах Телеграма.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>ЗАПРЕЩЕНО:</h4>
                                    <ol>
                                        <li>
                                            Неспортивное поведение до / во время
                                            / после мероприятий;
                                        </li>
                                        <li>
                                            Оскорбление любого участника(ов)
                                            группы или третьих(его) лиц(а) в
                                            любой форме;
                                        </li>
                                        <li>Жесткая и опасная игра;</li>
                                        <li>
                                            Сообщения и комментарии,
                                            пропагандирующие религиозные секты и
                                            общества;
                                        </li>
                                        <li>
                                            Разжигание расовой, национальной и
                                            религиозной ненависти;
                                        </li>
                                        <li>
                                            Умышленное и неумышленное
                                            провоцирование оппонентов на
                                            оскорбления и нецензурные выражения;
                                        </li>
                                        <li>
                                            Пропаганда, а также любое упоминание
                                            наркотических веществ,
                                            нетрадиционных межличностных
                                            отношений и других криминальных тем
                                            вне зависимости от цели высказывания
                                            (порицание, шутка и проч.);
                                        </li>
                                        <li>
                                            Размещение информации коммерческого
                                            характера в личных целях;
                                        </li>
                                        <li>
                                            Размещение или упоминание информации
                                            по организации или проведению
                                            сторонних мероприятий, не
                                            согласованных с администрацией
                                            нашего сообщества;
                                        </li>
                                        <li>
                                            Размещение ссылок на вирусные,
                                            троянские и другие вредоносные
                                            программы.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>Актив и Администрация.</h4>
                                    <p>
                                        Актив и Администрация группы оставляют
                                        за собой следующие права:
                                    </p>
                                    <ol>
                                        <li>
                                            Без предупреждения удалять любые
                                            сообщения и информацию, которые не
                                            соответствуют правилам группы;
                                        </li>
                                        <li>
                                            Без предупреждения закрывать доступ
                                            участникам в группу или на
                                            мероприятия группы;
                                        </li>
                                        <li>
                                            Корректировать состав и количество
                                            участников организации и групп по
                                            своему усмотрению при условии
                                            возмещения финансовой задолженности
                                            перед участником.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>Ответственность.</h4>
                                    <ol>
                                        <li>
                                            Актив и Администрация группы не
                                            несут ни гражданской, ни уголовной
                                            ответственности за информацию,
                                            опубликованную участниками
                                            сообщества, а также за возможный
                                            ущерб от этой информации. Вся
                                            информация публикуется исключительно
                                            для ознакомительного характера и не
                                            может быть использована в
                                            противозаконных целях;
                                        </li>
                                        <li>
                                            Администрация оставляет за собой
                                            право изменять данные правила группы
                                            по собственному усмотрению без
                                            предварительного уведомления
                                            участников группы, но с последующем
                                            оповещением об этом;
                                        </li>
                                        <li>
                                            Участие в группе подразумевает
                                            принятие и выполнение настоящих
                                            правил группы, поэтому любое
                                            нарушение этих правил будет
                                            считаться преднамеренным.
                                        </li>
                                    </ol>
                                    <p>
                                        Нарушение правил группы влечет за собой:
                                    </p>
                                    <ol>
                                        <li>Предупреждение участника;</li>
                                        <li>Бан — блокировка доступа;</li>
                                        <li>
                                            Удаление участника из рядов
                                            сообщества без возможности
                                            восстановления.
                                        </li>
                                    </ol>
                                    <p>
                                        Срок бана устанавливается в зависимости
                                        от тяжести нарушений или их количества
                                        (на усмотрение администраторов).
                                    </p>
                                    <p>
                                        Пользователь, получивший бан, по всем
                                        вопросам может обращаться к организатору
                                        https://t.me/Andrey_SSS ( Савин Андрей
                                        Юрьевич)
                                    </p>
                                </li>
                                <li>
                                    <h4>Примечания.</h4>
                                    <ol>
                                        <li>
                                            Всё то, что по каким-либо причинам
                                            не было указано в данных правилах,
                                            остается на усмотрение администрации
                                            группы.
                                        </li>
                                        <li>
                                            Если у Вас есть какие-то предложения
                                            или вопросы по содержанию правил
                                            нашей группы, направляйте их
                                            администрам групп.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <h4>
                                        Правила мероприятий и запись на них.
                                    </h4>
                                    <ol>
                                        <li>
                                            Спортивные мероприятия и запись на
                                            них проходят согласно доступному
                                            расписанию;
                                        </li>
                                        <li>
                                            Расписание публикуется в анонсах
                                            записи на игры;
                                        </li>
                                        <li>
                                            Основа при записи — внеочередное
                                            право на участие в мероприятии, т.е.
                                            ВСЕ участники при записи имеют
                                            равные права;
                                        </li>
                                        <li>
                                            Отсутствие ответа при записи и
                                            опросе может расцениваться как
                                            нарушение правил с соответствующим
                                            взысканием с участника. Понятие
                                            форс-мажор при записи и оплате
                                            отсутствует и не принимается к
                                            рассмотрению и апелляции;
                                        </li>
                                        <li>
                                            Участвовать в играх может только
                                            утвержденный при процессе записи
                                            состав.
                                        </li>
                                        <li>
                                            Он отображается в записи как
                                            ФИНАЛЬНЫЙ СПИСОК;
                                        </li>
                                        <li>
                                            Мероприятия, инвентарь и другие
                                            расходы в организации финансируются
                                            участниками сообщества на
                                            добровольных началах из собственных
                                            средств участников группы;
                                        </li>
                                        <li>
                                            Все оплаты как основы, так и разовых
                                            взносов после перечисления не
                                            возвращаются плательщикам вне
                                            зависимости от причин или сроков;
                                        </li>
                                    </ol>
                                    <p>
                                        Данные правила могут меняться без
                                        согласования с участниками сообщества.
                                        Об изменении правил своевременно
                                        происходит информирование участников
                                        СОЮЗа.
                                    </p>
                                    <p>ЖЕЛАЕМ ВАМ УСПЕШНОЙ ИГРЫ)</p>
                                </li>
                            </ol>
                        </React.Fragment>
                    ),
                },
                {
                    tabName: 'Регистрация',
                    innerContent: (
                        <React.Fragment>
                            <p>
                                Все желающие поучаствовать в играх, проводимых
                                нашей группой, вступают в число участников
                                СОЮЗа.
                            </p>
                            <p>
                                Для этого они проходят регистрацию в телеграм
                                боте по ссылке http://t.Me/UNION_Moscow_bot
                            </p>
                            <p>
                                После взаимодействия с ботом информация
                                отправляется на модерацию и администрация исходя
                                из загружености группы, текущих вакантных мест и
                                других критериев принимает решение по одобрению
                                кандидата. При положительном ответе , кандидат
                                получает ответ в том же роботе знакомится с
                                правилами и после проходит элементаный тест на
                                знание правил. После этого кандидат становится
                                полноправным участником и получает возможность
                                выбрать интересные для него игровые дни . С
                                помощью кнопок в боте УПРАВСОСТАВ он
                                присоединяется к телеграмгруппам соответствующим
                                выбранным игровым дням. В описаниях этих
                                телеграм групп участник может познакомиться со
                                временем записи на игру. Согласно расписания бот
                                УПРАВСОСТАВ присылает сообщения которые в
                                интуитивно понятной форме организуют процесс
                                записи на игру и оплату игровых взносов.
                            </p>
                        </React.Fragment>
                    ),
                },
            ],
        },
    ],
    textImageSections: [
        {
            typeTitle: 'h2',
            sectionTitle: 'Играть в зале',
            sectionType: 'futsal',
            typeContent: 'tabs',
            href: 'futsal',
            isCentered: false,
            content: [
                {
                    tabName: 'Информация',
                    innerContent: (
                        <div className='text-image'>
                            <ul className='content-text wow animate__animated animate__fadeInLeft '>
                                <li>Мы играем в зале СК МГИМО</li>
                                <li>Прекрасный "мягкий" зал 40х20 метров</li>
                                <li>Формат 5 на 5 , турнир "в 3 команды</li>
                                <li>Мяч №4 с заниженным отскоком</li>
                                <li>Две группы по уровню игры</li>
                                <li>
                                    Манишки, электронное табло и турнирная
                                    таблица игрового дня
                                </li>
                                <li>
                                    Интересный сезонный чемпионат для участников
                                </li>
                                <li>Видеосъемка всех игр и фото участников</li>
                                <li>Отдельная раздевалка с душевыми</li>
                                <li>Организованное судейство матчей</li>
                                <li>Всегда дружеская атмосфера на играх</li>
                                <li>Дружим с дисциплиной</li>
                            </ul>
                            <div className='content-image'>
                                <img
                                    src={futsal}
                                    className='wow animate__animated animate__fadeInRight'
                                />
                            </div>
                        </div>
                    ),
                },
                {
                    tabName: 'Расписание',
                    innerContent: (
                        <React.Fragment>
                            <Schedule type='futsal' />
                        </React.Fragment>
                    ),
                },
                {
                    tabName: 'Карта',
                    innerContent: (
                        <React.Fragment>
                            <Map
                                src='https://yandex.ru/map-widget/v1/?um=constructor%3Acc054e7951d907f580ee0317c11e74ce4109fcd06acb785596fb8919468878fa&amp;source=constructor'
                                width='100%'
                                height='450'
                                frameBorder='0'
                            />
                        </React.Fragment>
                    ),
                },
            ],
        },
        {
            typeTitle: 'h2',
            sectionTitle: 'Играть на траве',
            href: 'football',
            sectionType: 'football',
            typeContent: 'tabs',
            isCentered: false,
            content: [
                {
                    tabName: 'Информация',
                    innerContent: (
                        <div className='text-image'>
                            <ul className='content-text wow animate__animated animate__fadeInLeft '>
                                <li>
                                    Играем СК Лужники на травяной площадке 60 х
                                    30 метров под открытым небом
                                </li>
                                <li>
                                    Прекрасный газон с подогревом, раздевалки с
                                    душевыми
                                </li>
                                <li>Формат 7на 7 мяч №5</li>
                                <li>
                                    Круглый год, разный уровень, судейство,
                                    манишки, строго в "сороконожках"
                                </li>
                                <li>
                                    Дружеская атмосфера и интереснная борьба
                                </li>
                            </ul>
                            <div className='content-image'>
                                <img
                                    src={futbol}
                                    className='wow animate__animated animate__fadeInRight'
                                />
                            </div>
                        </div>
                    ),
                },
                {
                    tabName: 'Расписание',
                    innerContent: (
                        <React.Fragment>
                            <Schedule type='football' />
                        </React.Fragment>
                    ),
                },
                {
                    tabName: 'Карта',
                    innerContent: (
                        <React.Fragment>
                            <Map
                                src='https://yandex.ru/map-widget/v1/?um=constructor%3A1c67605d70e9ac63bfe134117c95e895aedc0b5fae748133548004ed4ee36b87&amp;source=constructor'
                                width='100%'
                                height='450'
                                frameBorder='0'
                            />
                        </React.Fragment>
                    ),
                },
            ],
        },
    ],
    popAsk: {
        buttonText: 'Задать вопрос',
        cardMainText: 'У вас остались вопросы?',
        cardLeftText: 'Вы можете задать нам вопрос в соцсетях',
        cardCenterText: 'ИЛИ',
        cardRightText: 'Вы можете задать свой вопрос ниже',
        telegramHref: 'https://t.me/Andrey_SSS',
        vkHref: 'https://vk.com/andrey88888savin',
        whatsappHref: 'https://wa.me/79037552612',
        sendButtonText: 'Отправить',
        nameValue: 'Ваше имя',
        phoneValue: 'Ваш номер телефона',
        questionValue: 'Ваш вопрос',
    },
    popRegistration: {
        buttonText: 'Играть с нами',
        buttonHref: 'https://t.me/UNION_Moscow_bot',
    },
    scheduleFutsal: [
        {
            day: 'Понедельник',
            type: 'Зал',
            time: '21:30',
            place: 'СК МГИМО',
        },
        {
            day: 'Вторник',
            type: 'Зал',
            time: '21:30',
            place: 'СК МГИМО',
        },
        {
            day: 'Среда',
            type: 'Зал',
            time: '22:00',
            place: 'СК МГИМО',
        },
        {
            day: 'Четверг',
            type: 'Зал',
            time: '22:00',
            place: 'СК МИРЭА',
        },
        {
            day: 'Пятница',
            type: 'Зал',
            time: '20:00',
            place: 'СК МГИМО',
        },
        {
            day: 'Суббота',
            type: 'Зал',
            time: '20:00',
            place: 'СК МГИМО',
        },
        {
            day: 'Воскресенье',
            type: 'Зал',
            time: '10:30',
            place: 'СК МГИМО',
        },
    ],
    scheduleFootball: [
        {
            day: 'Четверг',
            time: '22:00',
            place: 'СК Лужники',
        },
        {
            day: 'Воскресенье',
            time: '10:00',
            place: 'СК Лужники',
        },
    ],
}

export default function appReducer(state = initialState, action) {
    return state
}
