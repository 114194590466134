import React, { Component } from 'react'
import { connect } from 'react-redux'

import './Schedule.scss'

class Schedule extends Component {
    render() {
        let schedule
        if (this.props.type === 'futsal')
            schedule = this.props.scheduleFutsal.map((day) => {
                return (
                    <ul className='day'>
                        <li className='weekday'>{day.day}</li>
                        <li className='time'>
                            <i class='fa fa-2x fa-clock-o fa-2x schedule-icon' />
                            <span>{day.time}</span>
                        </li>
                        <li className='place'>
                            <i className='fa fa-map-marker fa-2x schedule-icon' />
                            <span>{day.place}</span>
                        </li>
                    </ul>
                )
            })
        else
            schedule = this.props.scheduleFootball.map((day) => {
                return (
                    <ul className='day'>
                        <li className='weekday'>{day.day}</li>
                        <li className='time'>
                            <i class='fa fa-2x fa-clock-o fa-2x schedule-icon' />
                            <span>{day.time}</span>
                        </li>
                        <li className='place'>
                            <i className='fa fa-map-marker fa-2x schedule-icon' />
                            <span>{day.place}</span>
                        </li>
                    </ul>
                )
            })
        return (
            <div
                className={
                    'schedule wow animate__animated animate__fadeInLeft schedule-' +
                    this.props.type
                }>
                {schedule}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        scheduleFutsal: state.appReducer.scheduleFutsal,
        scheduleFootball: state.appReducer.scheduleFootball,
    }
}

export default connect(mapStateToProps)(Schedule)
