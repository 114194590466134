import React, { Component } from 'react'

import './PhoneButton.scss'

class PhoneButton extends Component {
    render() {
        return <i className='phone-button fa fa-phone fa-3x'></i>
    }
}

export default PhoneButton
