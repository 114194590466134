import React, { Component } from 'react'
import { connect } from 'react-redux'

import Icons from './Icons/Icons'
import Navigation from './Navigation/Navigation'
import PhoneButton from '../PhoneButton/PhoneButton'

import './Header.scss'

class Header extends Component {
    render() {
        return (
            <header>
                <div className='main-header wow fadeInDown'>
                    <img
                        className='logo'
                        src={this.props.logo}
                        alt={this.props.logoAlt}
                    />
                    <div className='text-main-header'>
                        <span
                            className='page-title'
                            href={this.props.pageTitleHref}>
                            {this.props.pageTitle}
                        </span>
                        <span className='slogan'>{this.props.slogan}</span>
                    </div>
                </div>

                <a
                    className='fa fa-volume-control-phone fa-3x mobile-phone'
                    href='tel:+79037552612'></a>
                <Icons visibleOn='desktop' icons={this.props.icons} />
                <Navigation links={this.props.links} icons={this.props.icons} />
            </header>
        )
    }
}

function mapStateToProps(state) {
    return {
        logo: state.headerReducer.logo,
        logoAlt: state.headerReducer.logoAlt,
        pageTitle: state.headerReducer.pageTitle,
        pageTitleHref: state.headerReducer.pageTitleHref,
        slogan: state.headerReducer.slogan,
        icons: state.headerReducer.icons,
        links: state.headerReducer.links,
    }
}

export default connect(mapStateToProps)(Header)
