import logo from '../../assets/img/logo.png'

const initialState = {
    logo: logo,
    logoAlt: 'СОЮЗ поиграть в мини-футбол, футбол в Москве',
    pageTitle: 'СОЮЗ',
    pageTitleHref: '#',
    slogan: 'Спортивное Общество Юго-Запад',
    icons: [
        {
            class: 'fa-volume-control-phone',
            href: 'tel:+79037552612',
        },
        {
            class: 'fa-telegram',
            href: 'https://t.me/mini_football_moscow',
        },
        {
            class: 'fa-vk',
            href: 'https://vk.com/minifootballuzao',
        },
        {
            class: 'fa-whatsapp',
            href: 'https://wa.me/79037552612',
        },
    ],
    links: [
        {
            anchor: 'Играть в зале',
            href: 'futsal',
        },
        {
            anchor: 'Играть на траве',
            href: 'football',
        },
        {
            anchor: 'Важное',
            href: 'important',
        },
    ],
}

export default function headerReducer(state = initialState, action) {
    return state
}
