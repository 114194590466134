import React, { Component } from 'react'
import { connect } from 'react-redux'

import Header from './Header/Header'
import Invitation from './Invitation/Invitation'
import TextSection from './Sections/TextSection/TextSection'
import TextImageSection from './Sections/TextImageSection/TextImageSection'
import PopRegistrationCard from './PopRegisration/PopRegistrationCard/PopRegistrationCard'
import PopRegistrationButton from './PopRegisration/PopRegistrationButton/PopRegistrationButton'
import PopAskButton from './popAsk/PopAskButton/PopAskButton'
import PopAskCard from './popAsk/PopAskCard/PopAskCard'
import Important from './Components/Important/Important'
import Footer from './Footer/Footer'

import './App.scss'

class App extends Component {
    state = {
        isVisiblePopRegistration: false,
        isVisiblePopAsk: false,
        isSend: false,
    }

    togglePopRegistration = () => {
        this.setState({
            isVisiblePopRegistration: !this.state.isVisiblePopRegistration,
        })
    }

    togglePopAsk = () => {
        this.setState({
            isVisiblePopAsk: !this.state.isVisiblePopAsk,
        })
    }

    render() {
        return (
            <React.Fragment>
                <Header />
                <Invitation
                    isVisiblePopRegistration={
                        this.state.isVisiblePopRegistration
                    }
                    togglePopRegistration={this.togglePopRegistration}
                />
                <TextSection section={this.props.textSections[0]} />
                <TextImageSection section={this.props.textImageSections[0]} />
                <TextImageSection section={this.props.textImageSections[1]} />
                <PopRegistrationButton
                    popRegistration={this.props.popRegistration}
                />
                <PopAskButton
                    popAsk={this.props.popAsk}
                    isVisiblePopAsk={this.state.isVisiblePopAsk}
                    togglePopAsk={this.togglePopAsk}
                />
                <PopAskCard
                    popAsk={this.props.popAsk}
                    isSend={this.state.isSend}
                    isVisiblePopAsk={this.state.isVisiblePopAsk}
                    togglePopAsk={this.togglePopAsk}
                />
                <TextSection section={this.props.textSections[1]} />
                <Footer />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        textSections: state.appReducer.textSections,
        textImageSections: state.appReducer.textImageSections,
        popRegistration: state.appReducer.popRegistration,
        popAsk: state.appReducer.popAsk,
    }
}

export default connect(mapStateToProps)(App)
