import React, { Component } from 'react'

import SimpleContent from '../../Components/SimpleContent/SimpleContent'
import TabsContent from './TabsContent/TabsContent'

import './TextImageSection.scss'

export default class TextImageSection extends Component {
    render() {
        let sectionTitle, sectionContent
        let sectionClasses = 'text-image-section'

        switch (this.props.section.typeTitle) {
            case 'h1':
                sectionTitle = (
                    <h1 className='section-title'>
                        {this.props.section.sectionTitle}
                    </h1>
                )
                break
            default:
                sectionTitle = (
                    <h2 className='section-title'>
                        {this.props.section.sectionTitle}
                    </h2>
                )
                break
        }

        switch (this.props.section.typeContent) {
            case 'tabs':
                sectionContent = (
                    <TabsContent
                        innerImg={'../../assets/img/football.jpg'}
                        content={this.props.section.content}
                    />
                )
                break
            default:
                sectionContent = (
                    <SimpleContent
                        innerImg={'../../assets/img/football.jpg'}
                        content={this.props.section.content}
                    />
                )
                break
        }

        return (
            <section
                className={
                    'text-image-section ' +
                    (this.props.section.sectionType === 'futsal'
                        ? ' futsal-section'
                        : ' football-section')
                }
                id={this.props.section.href}>
                <div className='glass' />
                {sectionTitle}
                {sectionContent}
                <div className='glass' />
            </section>
        )
    }
}
