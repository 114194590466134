import React, { Component } from 'react'

import './Importants.scss'

class Important extends Component {
    state = {
        r: true,
    }
    toggle = (e) => {
        this.setState({
            r: !this.state.r,
        })
    }
    render() {
        let rClass, bClass
        if (this.state.r) {
            rClass = 'red animate__animated animate__slideInLeft'
            bClass = 'blue animate__animated animate__slideOutRight'
        } else {
            rClass = 'red animate__animated animate__slideOutRight'
            bClass = 'blue animate__animated animate__slideInLeft'
        }
        return (
            <section className='important'>
                <h1 onClick={this.toggle}>Важное</h1>
                <div className={bClass}></div>
                <div className={rClass}></div>
            </section>
        )
    }
}

export default Important
