import React, { Component } from 'react'
import { connect } from 'react-redux'

import './Invitation.scss'

class Invitation extends Component {
    render() {
        return (
            <section className='invitation wow fadeInDown'>
                <div className='image image1'></div>
                <div className='image image2'>
                    <div className='photo-button-section'>
                        <div className='camera-button'>
                            <i className='fa fa-camera'></i>
                            <span>Фото</span>
                        </div>
                    </div>

                    <div className='video-button-section'>
                        <div className='video-button'>
                            <i className='fa fa-video-camera'></i>
                            <span>Видео</span>
                        </div>
                    </div>
                </div>
                <div className='image image3'></div>
                <p className='dark-section'>{this.props.darkText}</p>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        darkText: state.invitationReducer.darkText,
        firstImage: state.invitationReducer.firstImage,
        altFirstImage: state.invitationReducer.altFirstImage,
        secondImage: state.invitationReducer.secondImage,
        altSecondImage: state.invitationReducer.altSecondImage,
        thirdImage: state.invitationReducer.thirdImage,
        altThirdImage: state.invitationReducer.altThirdImage,
        buttonText: state.invitationReducer.buttonText,
        buttonHref: state.invitationReducer.buttonHref,
    }
}

export default connect(mapStateToProps)(Invitation)
