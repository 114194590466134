import React, { Component } from 'react'

import '../PopRegistrationButton/PopRegistrationButton.scss'
import '../../App.scss'

class PopRegistrationButton extends Component {
    render() {
        return (
            <a
                className={
                    this.props.isVisiblePopRegistration
                        ? 'invisible'
                        : 'animate__animated animate__shakeY animate__infinite animate__slower pop-registration-button'
                }
                href={this.props.popRegistration.buttonHref}>
                {this.props.popRegistration.buttonText}
            </a>
        )
    }
}

export default PopRegistrationButton
